import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from "react";
import {
  ApolloClient,
  ApolloQueryResult,
  OperationVariables,
  useMutation,
  useQuery
} from "@apollo/client";
import {
  deleteSessionMutation,
  queryGetUserForSession,
  updateUserForSessionMutation
} from "graphql-client/queries/session";
import { queryCartProjection } from "graphql-client/queries/cart-projection";
import noop from "lodash.noop";
import { useKeycloak } from "utils-keycloak/KeyCloak";
import isEmpty from "lodash.isempty";
import { memoryStorage } from "analytics/storage";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { useIsoCookies } from "hooks/useIsoCookies";
import getCountryFromUrl from "utils/getCountryFromUrl";
import useErrorDialog from "hooks/useErrorDialog";
import useAppData from "hooks/useAppData";
import { CountryCode } from "hooks/useCountry";
import { isValidPostalCode as getIsValidPostalCode } from "utils/postalcode-validation";
import { processEnvServer } from "hooks/useSsrHooks";
import useUserPreferences from "hooks/useUserPreferences";
import { countries } from "resources/countries-config.json";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import {
  COOKIE_WITH_SESSION,
  REQUIRED_PERMISSION_COUNTRIES
} from "utils/constants";
import { useCookiePermissionBannerSetAtom } from "hooks-atoms/useCookiePermissionBannerAtom";
import useCheckEmailPopUp from "hooks/useCheckEmailPopUp/useCheckEmailPopUp";
import { useIsCheckoutByWindowLocation } from "@RHCommerceDev/hooks/useIsCheckout/useIsCheckout";

export interface SessionContextType {
  authenticated: boolean;
  deleteSession: () => void;
  currentCartId: Maybe<string>;
  loading: boolean;
  refetchGetUserForSession: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<Query>>;
  rhUser: UserType;
  location: string;
  validAssociate: Maybe<boolean>;
  userType: Maybe<string>;
  currentCurrency: Maybe<string>;
  sessionId: Maybe<string>;
  rhuid: Maybe<string>;
  membershipInfo: MembershipInfoType;
  cookiePreferences: CookiePreferencesType;
  getUserForSessionClient?: ApolloClient<any>;
  syncRefetchGetUserForSession: () => Promise<any>;
  cartProjection: CartProjection;
  refetchCartProjection: (variables?: Partial<QueryCartProjectionArgs>) => void;
  /* Used For Concierge Shared Components */
  setIsNewClient: (value: boolean) => void;
  selectedGallery: Maybe<Gallery>;
  selectedCustomer: any;
  setSelectedCustomer: (value: any) => void;
  setUserSession: React.Dispatch<
    React.SetStateAction<SessionUserType | undefined>
  >;
  isAutoMemberShipAdded: Maybe<string>;
}

export const SessionContext = createContext<SessionContextType>({
  authenticated: false,
  deleteSession: noop,
  currentCartId: "",
  loading: false,
  refetchGetUserForSession: async () => new Promise<any>(() => {}),
  rhUser: {} as UserType,
  sessionId: "",
  rhuid: "",
  validAssociate: true,
  userType: "",
  currentCurrency: "USA",
  location: "",
  membershipInfo: {} as MembershipInfoType,
  cookiePreferences: {} as CookiePreferencesType,
  getUserForSessionClient: {} as ApolloClient<any>,
  syncRefetchGetUserForSession: async () => new Promise<any>(() => {}),
  cartProjection: {} as CartProjection,
  refetchCartProjection: noop,
  setUserSession: noop,
  /* Used For Concierge Shared Components */
  selectedCustomer: null,
  setSelectedCustomer: () => {},
  selectedGallery: {} as Gallery,
  setIsNewClient: () => {},
  isAutoMemberShipAdded: ""
});

const SessionProvider: React.FC = ({ children }) => {
  const env = useEnv();
  const { keycloak } = useKeycloak();
  const { app, setApp } = useAppData();
  const { showError } = useErrorDialog();
  const { cookiePermissionCountry, setCookiePermissionCountry } =
    useCookiesWithPermission();

  const setPermissionCountry = useCookiePermissionBannerSetAtom();

  const {
    FEATURE_PDP_CART_BROKER,
    FEATURE_INTERNATIONAL,
    FEATURE_ADD_ITEM_CREATE_CART
  } = useEnv();
  let [userSession, setUserSession] = useState<SessionUserType>();
  const [updateUserForSessionCTR, setUpdateUserForSessionCTR] = useState(0);
  const [
    loadingSyncRefetchGetUserForSession,
    setLoadingSyncRefetchGetUserForSession
  ] = useState(false);
  const { handleSaveCookies } = useUserPreferences();
  const cookies = useIsoCookies();
  const [currentCurrency, setCurrentCurrency] = useState("USA");

  const { PUBLIC_URL } = useEnv();
  const isGuesthouse = PUBLIC_URL?.match(
    /^(?:http(?:s)?:\/\/)?(?:[^\.]+\.)?rhguesthouse/
  );
  const { isCheckout } = useIsCheckoutByWindowLocation();

  const userTypes = ["REGISTERED", "TRADE", "CONTRACT"];
  const userType = userSession?.rhUser?.userType ?? "ANONYMOUS";
  const authenticated =
    (keycloak?.authenticated || (userType && userTypes.includes(userType))) ??
    false;

  const country = getCountryFromUrl();

  const pc = useIsoCookies(["pc"], true)?.pc;

  const { emailPopupData, setSessionCookie } = useCheckEmailPopUp();

  const isValidPostalCode = useMemo(
    () => getIsValidPostalCode(pc, country as CountryCode),
    [country, pc]
  );

  const postalCode = useMemo(
    () =>
      isValidPostalCode ? pc : countries[country]?.defaultValues?.postalCode,
    [country, isValidPostalCode, pc]
  );

  const runOnce = useRef(false);
  const handleSaveCookiesRunOnce = useCallback(
    (userSession: SessionUserType) => {
      if (runOnce?.current) {
        return;
      }

      if (!isValidPostalCode) {
        handleSaveCookies({ country, postalCode: postalCode }, userSession);
      }
      runOnce.current = true;
    },
    [country, handleSaveCookies, isValidPostalCode, pc]
  );

  const [deleteSession] = useMutation<Mutation>(deleteSessionMutation, {
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onError: () => {
      keycloak?.logout();
    },
    onCompleted: () => {
      keycloak?.logout();
    }
  });

  const {
    client: getUserForSessionClient,
    loading: loadingGetUserForSession,
    refetch: refetchGetUserForSession,
    data: { getUserForSession } = {} as Query
  } = useQuery<Query>(queryGetUserForSession, {
    fetchPolicy: "no-cache",
    variables: {
      useCartBroker: yn(FEATURE_PDP_CART_BROKER),
      postalCode: pc,
      ...(yn(FEATURE_INTERNATIONAL) ? { country } : null),
      createCart: !yn(FEATURE_ADD_ITEM_CREATE_CART),
      /** we want to avoid updating the postal code or country of the cart while we are in the checkout flow... causes address drops */
      doNotMutateCart: isCheckout
    },
    onCompleted({ getUserForSession }) {
      handleSaveCookiesRunOnce(getUserForSession);

      if (!cookiePermissionCountry) {
        if (
          REQUIRED_PERMISSION_COUNTRIES.includes(
            getUserForSession?.rhUser?.akamaiCountryCode || ""
          )
        ) {
          setCookiePermissionCountry(
            getUserForSession?.rhUser?.akamaiCountryCode || ""
          );
        } else {
          setPermissionCountry(
            (getUserForSession?.cookiePreferences?.cookieRules as string) || ""
          );
        }
      }
    }
  });

  const [updateUserForSessionQuery, { loading: loadingUpdateUserForSession }] =
    useMutation<Mutation>(updateUserForSessionMutation, {
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onCompleted: ({ updateUserForSession }) => {
        !isGuesthouse && setUserSession(updateUserForSession);
      },
      onError: showError
    });

  useEffect(() => {
    if (getUserForSession) {
      setUserSession(getUserForSession);
      if (
        isEmpty(emailPopupData) ||
        emailPopupData?.sessionId !== getUserForSession?.sessionId
      ) {
        setSessionCookie?.(COOKIE_WITH_SESSION, {
          sessionId: getUserForSession?.sessionId,
          isPopClosed: false,
          clickCount: 1
        });
      }
    }
    if (getUserForSession?.rhUser?.postalCode) {
      setApp({ ...app, postalCode: getUserForSession.rhUser?.postalCode });
    }
  }, [getUserForSession]);

  const rhUser = useMemo(
    () => userSession?.rhUser || {},
    [userSession?.rhUser]
  );

  const isUserAvailable = Boolean(rhUser.id && rhUser.email);

  const skipCartProjection = !isUserAvailable || !userSession?.currentCartId;

  const {
    data: { cartProjection } = {} as Query,
    refetch: refetchCartProjection
  } = useQuery<Query, QueryCartProjectionArgs>(queryCartProjection, {
    variables: {
      userId: rhUser?.id ?? (null as unknown as string),
      email: rhUser?.email ?? null
    },
    skip: skipCartProjection,
    fetchPolicy: "no-cache"
  });

  const handleRefetchCartProjection = useCallback(
    (variables: Partial<QueryCartProjectionArgs>) => {
      // Ensure that query is skipped when refetch is called elsewhere
      if (!skipCartProjection) {
        refetchCartProjection(variables);
      }
    },
    [refetchCartProjection, skipCartProjection]
  );

  const UNKNOWNpreventer = useCallback((text = "") => {
    try {
      return text?.includes("UNKNOWN") || text?.includes("undefined")
        ? undefined
        : text;
    } catch (error) {
      return undefined;
    }
  }, []);

  const isCheckoutAndFeatureFasterCheckoutSignIn =
    yn(env?.FEATURE_FASTER_CHECKOUT_SIGN_IN) && isCheckout;
  useEffect(() => {
    if (
      !isGuesthouse &&
      !isCheckoutAndFeatureFasterCheckoutSignIn &&
      !isEmpty(keycloak?.tokenParsed) &&
      userSession?.rhUser?.email !== keycloak?.tokenParsed?.email &&
      updateUserForSessionCTR < 1 &&
      !loadingGetUserForSession &&
      !!userSession
    ) {
      updateUserForSessionQuery({
        variables: {
          currentCartId: userSession?.currentCartId ?? "",
          useCartBroker: yn(FEATURE_PDP_CART_BROKER),
          userInput: {
            firstName: UNKNOWNpreventer(keycloak?.tokenParsed?.given_name),
            lastName: UNKNOWNpreventer(keycloak?.tokenParsed?.family_name),
            email: keycloak?.tokenParsed?.email,
            platform: "NEW",
            userType: userSession?.rhUser?.userType,
            ...(yn(FEATURE_INTERNATIONAL)
              ? {
                  country: getCountryFromUrl() || "US"
                }
              : null)
          }
        },
        update: (cache, result) => {
          const variables = {
            userId: keycloak?.tokenParsed?.rhuid,
            email: keycloak?.tokenParsed?.email
          };
          handleRefetchCartProjection(variables);
          cache.writeQuery({
            query: queryGetUserForSession,
            data: { getUserForSession: result?.data?.updateUserForSession }
          });
        }
      });
    }
    if (userSession?.rhUser) {
      memoryStorage.setItem("analytics-userInfo", userSession?.rhUser);
    }
    if (authenticated && userSession?.rhUser && !isGuesthouse) {
      setUpdateUserForSessionCTR(1);
    }
  }, [
    keycloak?.tokenParsed,
    userSession,
    userSession?.currentCartId,
    userSession?.rhUser?.userType,
    userSession?.rhUser?.email,
    rhUser,
    loadingGetUserForSession,
    FEATURE_PDP_CART_BROKER,
    handleRefetchCartProjection,
    authenticated,
    updateUserForSessionCTR,
    updateUserForSessionQuery,
    FEATURE_INTERNATIONAL,
    isGuesthouse,
    isCheckoutAndFeatureFasterCheckoutSignIn,
    UNKNOWNpreventer
  ]);

  const syncRefetchGetUserForSession = useCallback(async () => {
    setLoadingSyncRefetchGetUserForSession(true);
    const response = await getUserForSessionClient.query<Query>({
      query: queryGetUserForSession,
      fetchPolicy: "network-only",
      variables: {
        useCartBroker: yn(FEATURE_PDP_CART_BROKER),
        postalCode: pc,
        country,
        createCart: !yn(FEATURE_ADD_ITEM_CREATE_CART)
      }
    });
    setLoadingSyncRefetchGetUserForSession(false);
    return response;
  }, [
    FEATURE_ADD_ITEM_CREATE_CART,
    FEATURE_PDP_CART_BROKER,
    country,
    getUserForSessionClient,
    pc
  ]);

  const defaultProjetion = cartProjection || {
    totalItemQuantity: 0,
    totalLines: 0
  };

  const loading =
    loadingGetUserForSession ||
    loadingUpdateUserForSession ||
    loadingSyncRefetchGetUserForSession;

  if (processEnvServer) {
    userSession = getUserForSession;
  }

  return (
    <SessionContext.Provider
      value={{
        authenticated,
        location: userSession?.location ?? "",
        currentCartId: userSession?.currentCartId ?? "",
        rhUser: userSession?.rhUser as UserType,
        rhuid: userSession?.rhuid,
        validAssociate: userSession?.validAssociate,
        userType,
        currentCurrency,
        deleteSession,
        setUserSession,
        loading,
        refetchGetUserForSession,
        getUserForSessionClient,
        syncRefetchGetUserForSession,
        sessionId: userSession?.sessionId,
        isAutoMemberShipAdded: userSession?.isAutoMemberShipAdded ?? "",
        cookiePreferences: {
          userSavedCookiePreferences:
            userSession?.cookiePreferences?.userSavedCookiePreferences,
          preferencesFunctionalityCookie:
            userSession?.cookiePreferences?.preferencesFunctionalityCookie,
          strictlyNecessaryCookie:
            userSession?.cookiePreferences?.strictlyNecessaryCookie,
          thirdPartyAnalyticsCookie:
            userSession?.cookiePreferences?.thirdPartyAnalyticsCookie,
          analyticsCookie: userSession?.cookiePreferences?.analyticsCookie,
          userAcceptedAllCookies:
            userSession?.cookiePreferences?.userAcceptedAllCookies,
          cookieRules: userSession?.cookiePreferences?.cookieRules
        },
        membershipInfo: {
          membershipSkuIsOnCart:
            userSession?.membershipInfo?.membershipSkuIsOnCart,
          userHasMembershipStatus:
            userSession?.membershipInfo?.userHasMembershipStatus,
          userHasActiveMembership:
            userSession?.membershipInfo?.userHasActiveMembership,
          membershipStatusDescription:
            userSession?.membershipInfo?.membershipStatusDescription,
          membershipSkuPrice: userSession?.membershipInfo?.membershipSkuPrice,
          currencyCode: userSession?.membershipInfo?.currencyCode,
          userHasCancelledMembership:
            userSession?.membershipInfo?.userHasCancelledMembership,
          userHasExpiredMembership:
            userSession?.membershipInfo?.userHasExpiredMembership,
          membershipId: userSession?.membershipInfo?.membershipId
        },
        cartProjection: defaultProjetion,
        refetchCartProjection: handleRefetchCartProjection,
        /* Used For Concierge Shared Components */
        setIsNewClient: () => {},
        selectedCustomer: null,
        setSelectedCustomer: () => {},
        selectedGallery: {} as Gallery
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
