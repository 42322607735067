import React, { Component, createContext } from "react";
import { ApolloProvider } from "@apollo/client";
import { PaletteType, useMediaQuery } from "utils/material-ui-core";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { KeycloakProviderAdapter } from "utils-keycloak/KeyCloak";
import theme from "theme";
import useBrand from "hooks-use-brand/useBrand";
import { useEnv } from "hooks/useEnv";
import { BREAKPOINT_SM } from "utils/constants";
import { processEnvServer } from "hooks/useSsrHooks";
import express from "express";
import memoize from "utils/memoize";
import { RHNotificationProvider } from "component-rh-notification-modal";
import type { ClientType } from "graphql-client/client-type";
import ErrorProvider from "./ErrorProvider";
import AvailableCountryProvider from "./AvailableCountryProvider";
import UserPreferencesProvider from "./UserPreferencesProvider";
import { getIsReactMicrosite } from "utils/getIsReactRoute";
import ProductConfiguratorProvider from "@RHCommerceDev/custom-providers/ProductConfiguratorProvider";
import SessionProvider from "./SessionProviderV2";
import CountrySiteProvider from "./CountrySiteProvider";

type PropType = { [key: string]: any };

class ErrorBoundary extends Component {
  state: { hasError: boolean };
  constructor(props: PropType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // TODO: Log this to service
    console.log("React Error", error, errorInfo);
  }

  render() {
    return this.state.hasError ? null : this.props.children;
  }
}

const EB = memoize(ErrorBoundary) as React.ComponentType;

export const UseRequestProvider: any = createContext({});
export const UseResponseProvider: any = createContext({});

export interface ProviderProps {
  type?: PaletteType;
  children: any;
  cookiePersistor?: any;
  req?: express.Request;
  client: ClientType;
}

export const Provider: React.FC<ProviderProps> = ({
  type,
  children,
  cookiePersistor = null,
  req = {} as express.Request,
  client
}) => {
  const brand = useBrand();
  // TODO: Is it possible to let CSS drive the grid completely, or
  // are we stuck with changing the theme on a breakpoint basis
  // which may cause heavy rerenders per breakpoint?
  const smDown = processEnvServer
    ? req.cookies.PF_EXP?.toLowerCase() === "mobile"
    : useMediaQuery(`(max-width: ${BREAKPOINT_SM}px)`);
  const isMicrosite = getIsReactMicrosite();
  const { FEATURE_RHR } = useEnv();
  return !isMicrosite ? (
    <EB>
      <UseRequestProvider.Provider value={req}>
        <KeycloakProviderAdapter cookiePersistor={cookiePersistor}>
          <ApolloProvider client={client}>
            <ThemeProvider
              theme={theme({
                type,
                brand,
                smDown,
                isRHR: FEATURE_RHR,
                reqContext: req
              })}
            >
              <UserPreferencesProvider>
                <ErrorProvider>
                  <RHNotificationProvider>
                    <ProductConfiguratorProvider>
                      <AvailableCountryProvider>
                        <CssBaseline />
                        <SessionProvider>
                          <CountrySiteProvider>{children}</CountrySiteProvider>
                        </SessionProvider>
                      </AvailableCountryProvider>
                    </ProductConfiguratorProvider>
                  </RHNotificationProvider>
                </ErrorProvider>
              </UserPreferencesProvider>
            </ThemeProvider>
          </ApolloProvider>
        </KeycloakProviderAdapter>
      </UseRequestProvider.Provider>
    </EB>
  ) : (
    <EB>
      <UseRequestProvider.Provider value={req}>
        <ApolloProvider client={client}>
          <ThemeProvider
            theme={theme({
              type,
              brand,
              smDown,
              isRHR: FEATURE_RHR,
              reqContext: req
            })}
          >
            <ErrorProvider>
              <CssBaseline />
              {children}
            </ErrorProvider>
          </ThemeProvider>
        </ApolloProvider>
      </UseRequestProvider.Provider>
    </EB>
  );
};

export default memoize(Provider);
