import React, { createContext, useContext, useMemo } from "react";
import { Brand } from "types";
import { PageModel } from "@RHCommerceDev/aem/types/pageModel";
import {
  convertResourceToFallback,
  getResourceByRoute,
  RouteToResource
} from "utils/resourcesUtils";
import { useEnv } from "hooks/useEnv";
import { useFetchModel } from "hooks/useFetchModel";
import { processEnvServer } from "hooks/useSsrHooks";
import { useLocation } from "react-router";
import { client } from "graphql-client";
import { queryGetAemModelGlobal } from "graphql-client-queries-get-aem-model";
import {
  globalQueryVariables,
  processModelResponse
} from "graphql-client-queries-get-aem-model";
import yn from "yn";

export interface LocationProviderContextProps {
  brand: Lowercase<Brand>;
  path: string;
  prefix?: string;
  isAemPage?: boolean;
  model?: any;
  modelClient?: any;
}

export interface ContextValue {
  pageContent: PageModel & { [key: string]: any };
  loading: boolean;
  error: boolean;
  path: string;
}

export const LocationContext = createContext<ContextValue>({
  pageContent: {},
  loading: true,
  error: false,
  path: ""
});

export interface PageContentContext {
  pageContent: PageModel & { [key: string]: any };
  loading: boolean;
}

export interface LocationProviderProps {
  aemModel: PageModel & { [key: string]: any };
}

const LocationProvider = ({ children, ...rest }) => {
  const location = useLocation();
  const graphqlClient = rest.client ? rest.client : client;
  const SSRCachedModel =
    !processEnvServer && (window as any).__APOLLO_STATE__
      ? graphqlClient.readQuery({
          query: queryGetAemModelGlobal,
          variables: globalQueryVariables(rest.path)
        })
      : false;
  const pageModel = useFetchModel(
    location.pathname,
    location.pathname.includes(".jsp"),
    !location.pathname.includes(".jsp") && !location.pathname.includes("admin"),
    SSRCachedModel ? processModelResponse(SSRCachedModel) : {},
    graphqlClient
  );
  return (
    <LocationContext.Provider value={pageModel}>
      {children}
    </LocationContext.Provider>
  );
};

export const usePageContent = () => {
  const env = useEnv();

  const defaultPageContentValue = "";
  const isFeatureDisplayMissingAemPageContentKeysEnabled = yn(
    env.FEATURE_DISPLAY_MISSING_AEM_PAGE_CONTENT_KEYS
  );
  const isFeatureDisplayMissingResourcePageContentKeysEnabled = yn(
    env.FEATURE_DISPLAY_MISSING_RESOURCE_PAGE_CONTENT_KEYS
  );

  const {
    pageContent,
    loading: pageContentLoading,
    path: pageContentPath
  } = useContext(LocationContext);

  const pageContentProxy = useMemo(() => {
    let resourceObj;
    try {
      resourceObj =
        typeof pageContent === "string" || pageContent instanceof String
          ? JSON.parse(pageContent as string)
          : pageContent;
    } catch (e) {
      console.warn(
        `Error generating \`resourceObj\` for path: "${pageContentPath}"`,
        e
      );
    }
    let fallbackObj;
    try {
      fallbackObj = convertResourceToFallback(
        getResourceByRoute(pageContentPath as RouteToResource)
      );
    } catch (e) {
      console.warn(
        `Error generating \`fallbackObj\` for path: "${pageContentPath}"`,
        e
      );
    }
    return new Proxy(fallbackObj || {}, {
      get(proxyObj, key) {
        if (!processEnvServer && pageContentLoading) {
          return defaultPageContentValue;
        }
        const mungedKey = String(key);
        if (resourceObj && Reflect.has(resourceObj, mungedKey)) {
          return Reflect.get(resourceObj, mungedKey);
        }
        if (isFeatureDisplayMissingAemPageContentKeysEnabled) {
          return `*** Missing AEM page-content for key: "${mungedKey}" ***`;
        }
        if (proxyObj && Reflect.has(proxyObj, mungedKey)) {
          return Reflect.get(proxyObj, mungedKey);
        }
        if (isFeatureDisplayMissingResourcePageContentKeysEnabled) {
          return `*** Missing resource page-content for key: "${mungedKey}" ***`;
        }
        return defaultPageContentValue;
      }
    });
  }, [
    isFeatureDisplayMissingAemPageContentKeysEnabled,
    isFeatureDisplayMissingResourcePageContentKeysEnabled,
    pageContent,
    pageContentLoading,
    pageContentPath
  ]);

  return { pageContent: pageContentProxy, pageContentLoading };
};

export const usePageContentNoProxy = () => {
  return useContext(LocationContext);
};

export default LocationProvider;
